$asset-host: 'https://app.evali.work'; $evali-storage-url: 'https://storage.googleapis.com/evali';
@charset "utf-8";

@import "colors";
@import "variables";

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/helpers/_all.sass"; // for bulma > 0.9.0
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/layout/footer.sass";
@import "~bulma/sass/layout/section.sass";
@import "~bulma/sass/layout/hero.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/form/shared.sass";
@import "~bulma/sass/form/tools.sass";
@import "~bulma/sass/form/checkbox-radio.sass";
@import "~bulma/sass/form/select.sass";
@import "~bulma/sass/form/input-textarea.sass";
@import "~bulma/sass/elements/notification.sass";
@import "~bulma/sass/elements/content.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/elements/table.sass";
@import "~bulma/sass/layout/footer.sass";
@import "~bulma/sass/components/modal.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/components/tabs.sass";

@import "mixins";
@import "progressbar";
@import "answer";
@import "typography";
@import "spinner";
@import "feedback";
@import "ertitem";
@import "evali-btn";
@import "test";
@import "form";
@import "modal";
@import "evali-app";
@import "respondent";
@import "project";
@import "user";
@import "btn2";
@import "list";
@import "grid";
@import "notification";
@import "testtaker-experience";
@import "magic-link";

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

*::selection {
  background-color: $clr-purple;
  color: white;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.table {
  th {
    font-weight: $weight-bold;
  }

  caption {
    text-align: left;
    font-size: 1.125rem;
    margin-top: 1rem;
    padding-left: 0.75rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid $grey-lighter;
  }
}

.text-content {
  max-width: 36rem;
  margin: 0 auto;
}

.test-container {
  margin: 1rem auto 0;
  min-height: 30rem;
  max-width: 36rem;

  position: relative;

  @include tablet {
    max-width: 46rem;
  }
}

.question {
  margin: 1rem 0 0;
  min-height: 8rem;
  text-align: center;
  font-size: 2rem;
  font-weight: $weight-bold;

  @include mobile {
    font-size: 1.5rem;
  }

  @media screen and (min-height: 450px) {
    margin-top: 4rem;
  }

  @media screen and (min-height: 550px) {
    margin-top: 6rem;
    min-height: 10rem;
  }
}

.answer-svg {
  text {
    pointer-events: none;
  }

  &__pie {
    &:hover {
      cursor: pointer;
      fill: rgba(255, 255, 255, 0.3);
    }
  }
}

.background-form {
  margin: 2rem -6rem 4rem;
  padding: 3rem 6rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.14);

  background-color: white;

  legend {
    font-weight: $weight-bold;
    font-size: 1.25rem;
    margin: 0 0 2rem;
  }

  .field {
    margin-bottom: 1rem;
  }

  @media (max-width: 50rem) {
    padding: 2rem 2rem;
    margin: 2rem -2rem 2rem;
  }

  @media (max-width: 40rem) {
    margin: 2rem -1rem 2rem;
    padding: 2rem 1rem;
  }
}

.flexflex {
  flex-grow: 1;
}

.mute {
  color: $grey-dark;
}

.mute-more {
  color: $grey;
}

.small {
  font-size: 0.875rem;
}

.text-bold {
  font-weight: 500;
}

.title .normal-weight,
.normal-weight {
  font-weight: 400;
}

.is-success {
  color: $clr-green;
}

.is-success-bg {
  background-color: $clr-green;
}

.is-fail {
  color: $clr-red;
}

.naked-abbr {
  text-decoration: none;
}

.update-available {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;

  &:hover {
    color: $clr-primary;
  }
}

.spread {
  display: flex;
  display: flex;
  justify-content: space-between;
}

.box {
  padding: 2rem;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);

  &-tight {
    padding: 1rem;
  }

  h1 {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    font-weight: $weight-normal;
  }
}

.content .page-title {
  color: black;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.content .switch-wrapper {
  margin-bottom: 1rem;
}

.checkbox input[type="checkbox"] {
  margin-right: 0.5rem;
}

.evali-box {
  background-color: $clr-blue;
  padding: 2rem;
  box-shadow: 0 2rem 2rem 0 rgba(0, 0, 0, 0.2);
  margin: 2rem;

  @include mobile {
    margin: 2rem 0.5rem;
  }
}

.outside-box {
  max-width: 26rem;
  min-width: 20rem;
  margin: 6rem auto 3rem;
  padding: 2rem;

  box-shadow: 0px 1rem 2rem rgba(77, 57, 128, 0.15);
  box-sizing: border-box;

  border: 2px solid $clr-blue;
  border-radius: 0.25rem;

  &--wide {
    max-width: 36rem;
    width: 36rem;
  }

  &--high {
    margin: 2rem auto;
  }

  &--fill {
    background-color: $white-ter;
  }

  legend {
    font-weight: $weight-bold;
    font-size: 2rem;
    margin: 1rem 0;
  }

  .legend--small {
    font-size: 1.25rem;
  }

  &__logo {
    display: block;
    margin: 1rem auto;
  }

  &__title {
    text-align: center;
    //font-weight: $weight-bold;
    font-size: 1.25rem;
    margin: 2rem auto 2rem;
  }

  &__account {
    text-align: center;
  }

  @include mobile {
    max-width: calc(100vw - 1rem);
  }
}

.evali-tt {
  display: flex;
  flex-direction: column;
  align-items: center;

  .test-request {
    p {
      margin-bottom: 1rem;
    }

    p.test-request__org {
      font-size: 1.25rem;
      font-weight: $weight-bold;
      margin-bottom: 0;
    }

    &__typerow {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }

    &__done {
      color: $clr-green;
    }

    &__pending {
      color: $grey-light;
    }

    &__decline {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__button {
        color: $clr-black-text;
      }
    }
  }
}

.no-scroll {
  overflow-y: hidden;
}

.fixme {
  position: fixed;
  width: 100vw;
}

.notification--narrow {
  max-width: 30rem;
}

.notification--centered {
  margin: 0 auto;
}

.share-box {
  @include raised-box($padding: 3rem 6rem);
  background-color: $white-ter;
  //border: 2px solid $clr-purple;
  font-size: 1.25rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__icons {
    a {
      display: inline-block;
      margin-bottom: 2rem;
      margin-right: 2rem;
      color: $black-bis;

      &:hover {
        color: $grey-darker;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__url {
    font-size: 0.8125rem;
  }

  @include mobile {
    @include raised-box($padding: 1rem 2rem);
    font-size: 1rem;
    align-items: flex-start;

    &__icons {
      a {
        display: inline-block;
        margin-bottom: 1rem;
        margin-right: 1rem;
      }
    }
  }
}

.mini-profile {
  p {
    margin-bottom: 0.5rem;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.testtype-image {
  margin: 0.5rem;
}

.invite-box {
  background-color: $clr-pink;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;

  &--account {
    background-color: $clr-green;
  }
}

.footer {
  .nav-item {
    margin-right: 3rem;
  }

  .nav-item:last-of-type {
    margin-right: 0;
  }
}

.inline-icon {
  line-height: 0;
}

.mr1 {
  margin-right: 1rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.single-result {
  &__summary {
    display: flex;
    flex-wrap: wrap;
  }

  &__competency {
    flex-basis: calc(50% - 4rem);
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.notification-settings {
  &__title {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;

    &__text {
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
}

.notification-setting {
  display: grid;
  grid-template-columns: 2fr 2fr;
  margin-bottom: 1rem;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    &__category {
      font-weight: $weight-bold;
    }

    &__icon {
      display: flex;
      align-items: center;

      svg {
        stroke: $clr-primary;
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
      }
    }
  }

  &__checkboxes-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__boxes {
      display: grid;
      grid-template-rows: 1fr 1fr;
    }

    .field {
      margin: 0;
    }
  }
}

.integration_form_legend {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.lobby-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 100;
  left: 50%;
  bottom: 30px;

  &.show {
    visibility: visible;
    -webkit-animation: snackbar__fadein 0.5s, snackbar__fadeout 0.5s 2.5s;
    animation: snackbar__fadein 0.5s, snackbar__fadeout 0.5s 2.5s;
  }
}

@-webkit-keyframes snackbar__fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes snackbar__fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes snackbar__fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes snackbar__fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.magic-link-background {
  position: fixed;
  top: 2rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -10;
  background: url("#{$evali-storage-url}/img/magic-link-bg.svg");
  background-repeat: repeat-x;
}

.magic-link-interview-guide {
  position: relative;
  margin-top: 3rem;

  .ml-ig-aspect {
    margin-bottom: 1rem;

    >ul {
      list-style: inherit;
      margin-left: 1rem;
      margin-bottom: 1rem;

      >li {
        margin-bottom: 1rem;
      }

      >.ml-ig-indicator {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.download-interview-guide-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}
